<template>
  <b-nav-item @click="skin = isDark ? 'semi-dark' : 'dark'">
    <b-avatar
      v-tooltip.hover="'主题'"
      v-ripple.400="$x.ripple.primary"
      variant="light-primary">
      <feather-icon
        size="18"
        class="x-link"
        :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
      />
    </b-avatar>
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BAvatar,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
