<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright  © 2020
      <b-link
        class="ml-25"
        href="http://www.cloudxin.ltd"
        target="_blank"
      >云信科技</b-link>
      <span class="d-none d-sm-inline-block ml-25">版权所有</span>
      <b-link
        class="ml-25"
        href="https://beian.miit.gov.cn"
        target="_blank"
      >苏ICP备20020189号-2</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
