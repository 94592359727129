<template>
  <b-nav-item @click="fullscreenToggle()">
    <b-avatar
      v-tooltip.hover="'全屏'"
      v-ripple.400="$x.ripple.primary"
      variant="light-primary">
      <feather-icon size="18" class="x-link" :icon=" isFullscreen ? 'MinimizeIcon' : 'MaximizeIcon'"/>
    </b-avatar>
  </b-nav-item>
</template>

<script>
import { BNavItem, BAvatar } from 'bootstrap-vue'
import screenfull from 'screenfull'

export default {
  components: {
    BNavItem,
    BAvatar,
  },
  data() {
    return {
      isFullscreen: false, // 全屏按钮
    }
  },
  created() {
    this.isFullscreen = screenfull.isFullscreen
  },
  methods: {
    // 全屏功能
    fullscreenToggle() {
      if (!screenfull.isEnabled) {
        this.$xtoast.error('您的浏览器无法使用全屏功能，请更换谷歌浏览器或者请手动点击F11按钮全屏展示！')
        return
      }
      screenfull.toggle()
      this.isFullscreen = !this.isFullscreen
    },
  },
}
</script>
