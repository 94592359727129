<template>
  <div
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="d-flex pl-2 align-items-center"
  >
    <span class="content-header-title float-left pr-1 mb-0 x-text-bold font-large-1">
      {{ $route.meta.pageTitle }}
    </span>
    <div class="breadcrumb-wrapper">
      <b-breadcrumb>
        <b-breadcrumb-item to="/">
          <feather-icon
            icon="HomeIcon"
            size="16"
            class="align-text-top"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-for="item in $route.meta.breadcrumb"
          :key="item.text"
          :active="item.active"
          :to="item.to"
        >
          {{ item.text }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
  },
}
</script>
