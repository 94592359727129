<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.nick_name || userData.real_name }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="36"
        :src="userData.avatar"
        text="CX"
        badge
        class="badge-minimal x-hover"
        badge-variant="success"
      >
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <b-avatar
        size="56"
        text="CX"
        :src="userData.avatar"
        style="border-radius: .5rem !important;"
        variant="light-primary">
      </b-avatar>
      <div class="d-flex flex-column x-text-bold ml-1 x-text-cut">
        <div class="x-text-bold x-text-cut font-medium-2">{{ userData.nick_name || userData.real_name }}</div>
        <div v-if="userData.email" class="text-secondary x-text-cut mt-50 ">{{ userData.email}}</div>
        <b-badge v-else variant="light-primary" class="mt-50" @click.stop="toRouteUrl(0)">邮箱未设置
        </b-badge>
      </div>
    </b-dropdown-item>
    <hr />
    <b-dropdown-item
      link-class="d-flex align-items-center justify-content-between x-text-bold"
      @click="toRouteUrl(0)"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          size="18"
          icon="UserIcon"
          class="mr-50 ml-50 "
        />
        <span>个人信息</span>
      </div>
      <feather-icon
        size="18"
        icon="ChevronRightIcon"
        class="mr-50 ml-50 "
      />
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center justify-content-between x-text-bold"
      @click="toRouteUrl(1)"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          size="18"
          icon="LockIcon"
          class="mr-50 ml-50 "
        />
        <span>安全设置</span>
      </div>
      <feather-icon
        size="18"
        icon="ChevronRightIcon"
        class="mr-50 ml-50 "
      />
    </b-dropdown-item>
    <hr />
    <b-dropdown-item
      link-class="d-flex align-items-center x-text-bold "
      @click="logout"
    >
      <feather-icon
        size="18"
        icon="LogOutIcon"
        class="mr-50 ml-50"
      />
      <span>退出登录</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar, BBadge,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')) || {},
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    toRouteUrl(index) {
      if (index === 0) {
        this.$router.push({ path: 'system-usercenter-basesettings' })
      }
      if (index === 1) {
        this.$router.push({ name: 'system-usercenter-securitysettings' })
      }
    },
  },
}
</script>
<style lang="scss">
</style>
